import { useState, useEffect } from 'react';

function useScrollPosition(): number {
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    useEffect(() => {
        function handleScroll() {
            setScrollPosition(window.pageYOffset);
        }
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return scrollPosition;
}

export default useScrollPosition;
