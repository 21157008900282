import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/app.scss';
import TopNavBar from './components/TopNavBar';
import Footer from './components/Footer';
import bkgd from './img/background1.png';
import HomePage from './areas/home/HomePage';
import AboutPage from './areas/about/AboutPage';
import OurWorkPage from './areas/ourWork/OurWorkPage';

function App() {
    return (
        <>
            <BrowserRouter>
                <TopNavBar />
                <main>
                    <Routes>
                        <Route index element={<HomePage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/our-work" element={<OurWorkPage />} />
                    </Routes>
                    <img className="bg" src={bkgd} alt="background" />
                </main>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
