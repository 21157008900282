import * as React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useScrollPosition from '../hooks/useScrollPosition';

const TopNavBar = () => {
    const scrollPosition = useScrollPosition();
    const lightDark = scrollPosition > 0 ? 'light' : 'dark';

    return (
        <Navbar collapseOnSelect expand="lg" fixed="top" bg={lightDark} variant={lightDark}>
            <Container>
                <Navbar.Brand href="#home">Amy Hink Design</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <Nav.Link as={Link} className="nav-link" to="/" eventKey={1}>
                            Home
                        </Nav.Link>
                        <Nav.Link as={Link} className="nav-link" to="/about" eventKey={2}>
                            About
                        </Nav.Link>
                        <Nav.Link as={Link} className="nav-link" to="/our-work" eventKey={3}>
                            Our Work
                        </Nav.Link>
                        <Nav.Link as={Link} className="nav-link" to="/contact" eventKey={4}>
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default TopNavBar;
