import { Row, Col } from 'react-bootstrap';
import useIsInMobileMode from '../../../hooks/useIsInMobileMode';
import Images from './Images';

interface LocalProps {
    title: string;
    description: string;
    images: string[];
    flip?: boolean;
}

const HomePage = ({ title, description, images, flip = false }: LocalProps) => {
    const isInMobileMode = useIsInMobileMode();
    if (isInMobileMode) {
        flip = false;
    }

    return (
        <div className="one-project">
            <Row>
                {flip ? <Col></Col> : <></>}
                <Col>
                    <h2>{title}</h2>
                </Col>
            </Row>
            <Row>
                {!flip ? (
                    <Col>
                        <div>{description}</div>
                    </Col>
                ) : (
                    <Col>
                        <Images images={images} />
                    </Col>
                )}
                {flip ? (
                    <Col>
                        <div>{description}</div>
                    </Col>
                ) : (
                    <Col>
                        <Images images={images} />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default HomePage;
