import { Col, Container, Row } from 'react-bootstrap';
import Project from './components/Project';
import brookfieldKitchen1 from '../../img/brookfield-kitchen-1.jpg';
import brookfieldKitchen2 from '../../img/brookfield-kitchen-2.jpg';
import muskegoSunroom from '../../img/muskego-sunroom.jpg';
import muskegoSunroom2 from '../../img/muskego-sunroom-2.jpg';
import muskegoSunroom3 from '../../img/muskego-sunroom-3.jpg';
import hartlandSunroom1 from '../../img/hartland-sunroom-1.jpg';
import hartlandSunroom2 from '../../img/hartland-sunroom-2.jpg';
import hartlandSunroom3 from '../../img/hartland-sunroom-3.jpg';
import muskegoBedroom1 from '../../img/muskego-bedroom-1.jpg';
import muskegoBedroom2 from '../../img/muskego-bedroom-2.jpg';
import muskegoBedroom3 from '../../img/muskego-bedroom-3.jpg';
import muskegoBedroom4 from '../../img/muskego-bedroom-4.jpg';
import muskegoBedroom5 from '../../img/muskego-bedroom-5.jpg';
import tropical1 from '../../img/midcentury-tropical-1.jpg';
import tropical2 from '../../img/midcentury-tropical-2.jpg';
import bathroom1 from '../../img/bathroom-1.jpg';
import bathroom2 from '../../img/bathroom-2.jpg';
import bathroom3 from '../../img/bathroom-3.jpg';

const HomePage = () => {
    const projects = [
        {
            title: 'Brookfield Kitchen',
            description: '[add description of the awesome kitchen here.]',
            images: [brookfieldKitchen1, brookfieldKitchen2],
        },
        {
            title: 'Muskego Sunroom',
            description: '[add description here.]',
            images: [muskegoSunroom, muskegoSunroom2, muskegoSunroom3],
        },
        {
            title: 'Hartland Sunroom',
            description: '[add description here.]',
            images: [hartlandSunroom1, hartlandSunroom2, hartlandSunroom3],
        },
        {
            title: 'Muskego Bedroom',
            description: '[add description here.]',
            images: [muskegoBedroom1, muskegoBedroom2, muskegoBedroom3, muskegoBedroom4, muskegoBedroom5],
        },
        {
            title: 'Mid Century Tropical',
            description: '[add description here.]',
            images: [tropical1, tropical2],
        },
        {
            title: 'Bathroom',
            description: '[add description here.]',
            images: [bathroom1, bathroom2, bathroom3],
        },
    ];

    return (
        <Container className="our-work-page">
            <Row>
                <Col className="text-center">
                    <h1>Our Work</h1>
                </Col>
            </Row>
            {projects.map((x, i) => (
                <Project {...x} flip={i % 2 !== 0} />
            ))}
        </Container>
    );
};

export default HomePage;
