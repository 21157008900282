import { Carousel } from 'react-bootstrap';

interface LocalProps {
    images: string[];
}

const Images = ({ images }: LocalProps) => {
    return (
        <>
            <Carousel>
                {images.map((x, i) => (
                    <Carousel.Item>
                        <img src={x} className="d-block w-100" alt="..." />
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
};

export default Images;
