import { Col, Container, Row } from 'react-bootstrap';

const HomePage = () => {
    return (
        <Container className="about-page">
            <Row>
                <Col className="text-center">
                    <h1>About Us</h1>
                </Col>
            </Row>
            <Row>
                <Col lg className="mb-3">
                    <div className="title-container">
                        <h2>What We Do</h2>
                        <hr />
                        <p>Amy Hink Design is an interior design company based in Brookfield, and specializes in residential interior design and decoration.</p>
                        <h5>We can create spaces of all styles:</h5>
                        <p>
                            Cabin/Lodge Coastal Cottage/Country Contemporary Eclectic Farmhouse French Country Global Inspired Industrial Midcentury Mission/Shaker Modern Rustic Scandinavian
                            Traditional Tropical
                        </p>
                        <h5>We design and decorate any area and room of your home:</h5>
                        <p>Bath Bedroom Baby/Kids room Basement Dining room Living room Family/Great room Entry Hall Home Bar Home Office Kitchen Mudroom Staircase Storage/Closet Outdoor Exterior</p>
                    </div>
                </Col>
                <Col lg>
                    <div className="title-container">
                        <h2>The Designer</h2>
                        <hr />
                        <p>Amy Hink, DSA Certified Professional Designer, R.I.D.Q.C Certified Designer</p>
                        <p>
                            Amy Hink is a graduate of the New York Institute of Art and Design. She is a RIDQC Certified Residential Interior Designer and a member of the Design Society of America.
                            Although she has been a professional interior designer for three years, creativity has always been a part of who she is. She also is an e-designer for Wayfair.com.
                        </p>
                        <p>
                            Amy was born in Milwaukee, Wisconsin. She grew up in West Allis where she was highly involved in track, softball, gymnastics, and volleyball. She attended UW-Green Bay on a
                            full volleyball scholarship and studied Biology with the emphasis in Exercise Physiology. After college, she moved to Charleston, SC where she played beach volleyball and
                            practiced massage therapy. She decided to follow her dream of being an interior designer and now resides in Brookfield, WI, where she is currently renovating her midcentury
                            home. She is married with two boys, and has a Maltese named, Snoop!
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default HomePage;
