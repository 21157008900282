import { faFacebook, faInstagramSquare, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

const Social = () => {
    return (
        <div className="social">
            <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
            <FontAwesomeIcon icon={faFacebook} size="2x" />
            <FontAwesomeIcon icon={faTwitter} size="2x" />
        </div>
    );
};

export default Social;
