import * as React from 'react';
import Social from './Social';

const Footer = () => {
    return (
        <div className="footer">
            <Social />
        </div>
    );
};

export default Footer;
