import { Container } from 'react-bootstrap';

const HomePage = () => {
    return (
        <Container className="home-page">
            <div className="top-spacer"></div>
            <div className="title-container">
                <div>
                    <h1>Amy Hink Design</h1>
                </div>
                <div>
                    <h3>Interior Design</h3>
                </div>
            </div>
        </Container>
    );
};

export default HomePage;
